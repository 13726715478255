<template>
  <v-row class="fill-height" style="padding-top:25px;">
    <v-col>
      <delete-event
        v-if="bookingWorkspace"
        :dialog="showDeleteEvent"
        :eventID="deleteEventID"
        :onClose="handleCloseDeleteEvent"
        :workspaceID="bookingWorkspace"
      />
      <move-event
        v-if="bookingWorkspace"
        :dialog="showMoveEvent"
        :eventID="moveEventID"
        :onClose="handleCloseMoveEvent"
        :workspaceID="bookingWorkspace"
      />
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            ref="today"
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
            :disabled="loading"
          >
          {{ $t('booking.calendar.today', locale) }}
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
            :disabled="loading"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
            :disabled="loading"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <!--v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title-->
          <v-menu
            v-if="$refs.calendar"
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                text
              >
                <span>
                  {{ $refs.calendar.title }}
                </span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item 
                v-for="item of monthList"
                :key="item.ID"
                @click="handleChangeMonth(item.ID)"
              >
                <v-list-item-title>{{ item.Name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer/>
          <v-autocomplete
            :label="$t('booking.activities.title', locale)"
            v-model="selectedActivitiesID"
            :loading="loading"
            :items="activities"
            class="mx-4"
            dense
            hide-no-data
            hide-details
            color="#123123"
            item-text="name"
            item-value="id"
            @change="handleFilterByActivityID"
            outlined
            multiple
            :disabled="loading"
          />
          <v-select
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="Conf. Semana"
            class="mx-2"
          />
          <v-spacer/>
          <multi-event-action
            :events="eventsFiltered.filter(x => new Date(x.start) >= new Date())"
            :locale="locale"
            :workspaceID="bookingWorkspace"
            :onRefresh="handleRefresh"
          />
          <v-btn-toggle 
            style="margin-right: 20px"
                color="grey darken-2"
          >
            <v-btn
              small
              color="grey darken-2"
              outlined
              @click="handleZoom(-20)"
            >
              <v-icon
                color="grey darken-2"
              >
                mdi-magnify-minus-outline
              </v-icon>
            </v-btn>

            <v-btn
              small
              color="grey darken-2"
              outlined
              @click="handleZoom(20)"
            >
              <v-icon
                color="grey darken-2"
              >
                mdi-magnify-plus-outline
              </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
              >
                <span>{{ $t('booking.calendar.'.concat(typeToLabel[type]), locale) }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{ $t('booking.calendar.day', locale) }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{ $t('booking.calendar.week', locale) }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{ $t('booking.calendar.month', locale) }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>{{ $t('booking.calendar.fourDays', locale) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet 
        :style="{ height: `calc(-140px + ${calendarHeight}vh)`}"
      >
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="eventsFiltered"
          :event-color="getEventColor"
          :event-text-color="getEventTextColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="handleGetEvents"
          :locale="locale"
          :weekdays="weekday"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <event 
            v-show="selectedEvent.source !== 'dviewcontent'"
            :showReservationMenu="showReservationMenu"
            :showActivityMenu="showActivityMenu"
            :selectedEvent="selectedEvent"
            :locale="locale"
            :onCloseEvent="handleCloseEvent"
            :onDeleteEvent="handleDeleteEvent"
            :onMoveEvent="handleMoveEvent"
          />
          <!--v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer/>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
              <v-img v-if="selectedEvent.image" :src="selectedEvent.image" style="width: 200px; height:auto;" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                color="primary"
                dark
                @click="selectedOpen = false"
              >
              {{ $t('common.close', locale) }}
              </v-btn>
            </v-card-actions>
          </v-card-->
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import Event from './Event'
import DeleteEvent from '@/components/deleteEvent/Index'
import MoveEvent from '@/components/moveEvent/Index'
import MultiEventAction from './MultiEventAction'
export default {
  components: {
    Event,
    DeleteEvent,
    MoveEvent,
    MultiEventAction,
  },
  data: () => ({
    calendarHeight: 100,
    start: null,
    end: null,
    showReservationMenu: false,
    showActivityMenu: false,
    loading: false,
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'month',
      week: 'week',
      day: 'day',
      '4day': 'fourDays',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    eventsFiltered: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    activities: [],
    selectedActivitiesID: null,

    showDeleteEvent: false,
    deleteEventID: null,
    showMoveEvent: false,
    moveEventID: null,

    monthList: null,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Dom - Sáb', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Dom', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Lun - Vie', value: [1, 2, 3, 4, 5] },
      { text: 'Lun, Mié, Vie', value: [1, 3, 5] },
      { text: 'Sáb, Dom', value: [6, 0] },
    ],
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
  },
  watch: {
    bookingWorkspace () {
      this.events = []
      this.eventsFiltered = []
      this.checkMenuPermissions()
      this.handleGetEvents()
    },
  },
  mounted () {
    this.monthList = this.prepareMonthList()
    this.checkMenuPermissions()
    this.$refs.calendar.checkChange()
  },
  methods: {
    prepareMonthList () {
      const result = []
      for (let i = 0; i < 12; i++) {
        const date = new Date()
        date.setMonth(date.getMonth() - i)
        result.push({
          ID: utils.getLastDayOfMonth(new Date(date)).toISOString().split('T').shift(),
          Name: new Date(date).toLocaleDateString(this.locale, {
            year: 'numeric',
            month: 'short',
          })
        })
      }
      return result
    },
    handleZoom (v) {
      this.calendarHeight += v
    },
    checkMenuPermissions() {
      const url = `v1/internal/workspaces/${this.bookingWorkspace}/sidebar`
      api.getAllWithoutLimit ('booking', url)
        .then(response => {
          this.showReservationMenu = response.filter(x => x.id === '1d16b79f-699b-4448-9471-415f24170dde').length > 0
          this.showActivityMenu = response.filter(x => x.id === 'af7c5c22-3bbc-4b85-9437-b2e3adda51c3').length > 0
        })
    },
    prepareDateTime (date, time) {
      const h = parseInt(time / 60)
      const m = time - (60 * h)
      return  new Date(date).setHours(h, m, 0)
    },
    handleGetEventsFiltered () {
      this.eventsFiltered = this.selectedActivitiesID && this.selectedActivitiesID.length > 0 ? this.events.filter(x => this.selectedActivitiesID.indexOf(x.activityID) >= 0) : this.events
      this.loading = false
    },
    handleRefresh () {
      this.handleGetEvents ({ start: this.start, end: this.end })
    },
    handleGetEvents (v) {
      this.loading = true
      if (v) {
        this.start = v.start
        this.end = v.end
      } 
      const start = this.start
      const end = this.end
      const filter = `?min=${start.date}&max=${end.date}&includeHideEvents=true&excludeHide=true&`
      api.getAllWithoutLimit('booking', `v1/private/workspaces/${this.bookingWorkspace}/events${filter}`, false)
        .then(response => {
          this.events = response.map(item => {
            console.log(item)
            return {
                id: item.ID,
                activityID: item.ActivityID,
              name: `${item.IsCustomLimit ? '✏' : ''}${item.IsCMDviewcontent ? '📆 ' :  item.IsActtiv ? '⚠ ' :  item.Source === 'dviewcontent' ? '🖥 ' : '' } ${utils.getTranslation(item.ActivityName, this.locale).concat(item.Hide ? ' [oculto]' : '')}`,
                image: item.Image,
                start: this.prepareDateTime(item.Date, item.Time),
                end: this.prepareDateTime(item.Date, item.Time + item.Duration),
                free: item.Free,
                color: item.Hide ? '#cccccc' : item.Color ? item.Color : this.colors[utils.rnd(0, this.colors.length - 1)],
                timed: !item.AllDay,
                source: item.Source,
                isActtiv: item.IsActtiv,
                Hide: item.Hide ? true : false
            }
          })
          this.getDistinctActivities()
          this.handleGetEventsFiltered()
        })
    },
    getDistinctActivities () {
      // this.activities = [...new Set(this.events.map(item => item.name))]
      this.activities = [...new Map(this.events.map(item => [item['activityID'], item])).values()].map(item => { return { id: item.activityID, name: item.name } })
      //console.log('jhm act', this.activities)
      //for (const item of this.activities) console.log('jhm act', item.id, item.name)

    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    getEventTextColor (event) {
      return utils.getOppositeColor(event.color)
    },
    handleChangeMonth (v) {
      this.focus = v
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    handleFilterByActivityID () {
      //alert(this.selectedActivityID)
      this.handleGetEventsFiltered()
    },
    handleDeleteEvent (v) {
      this.deleteEventID = v
      this.showDeleteEvent = false
      setTimeout(() => {
        this.showDeleteEvent = true
      }, 1000)
    },
    handleCloseEvent (update) {
      if (update) this.handleGetEvents()
      this.selectedOpen = false
    },
    handleCloseDeleteEvent (update) {
      if (update) this.handleGetEvents()
      this.deleteEventID = null
      this.showDeleteEvent = false
    },
    handleMoveEvent (v) {
      this.moveEventID = v
      this.showMoveEvent = true
    },
    handleCloseMoveEvent (update) {
      if (update) this.handleGetEvents()
      this.moveEventID = null
      this.showMoveEvent = false
    },
    /*
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = utils.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = utils.rnd(0, 3) === 0
        const firstTimestamp = utils.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = utils.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[utils.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[utils.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }
    },*/
  },
}
</script>

