<template>
  <v-layout wrap>
    <v-flex xs6>
      <v-date-picker
        v-if="date"
        v-model="date"
        :first-day-of-week="0"
        :locale="locale"
        style="width:100%"
        hide-details
      />
    </v-flex>
    <v-flex xs6>
      <interval-times
        v-if="time"
        style="float:right;"
        :label="`${$t('booking.scheduleForm.startTime')}:`"
        v-model="time"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import IntervalTimes from '@/components/intervalTimes/Index'
import utils from '@/services/utils'
export default {
  components: {
    IntervalTimes,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    date: null,
    time: null,
    minDate: utils.checkDate(new Date()),
  }),
  watch: {
    event () {
      this.prepareData()
    },
    date () {
      this.onChange({date: this.date, time: this.time})
    },
    time () {
      this.onChange({date: this.date, time: this.time})
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.date = utils.checkDate(this.event.Date)
      this.time = this.event.Time
    }
  }
}
</script>

