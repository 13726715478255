<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding-top: 0px" v-if="event">
        <v-card-text style="padding:0">
          <v-container>
            <h3>Seleccione la nueva configuraci�n</h3>
            <move-event-form
              style="margin-top:20px"
              :event="event"
              :locale="locale"
              :onChange="handleChangeEvent"
            />
          </v-container>
          <v-container style="padding: 0 20px" v-if="actionRequired">
            <v-alert
              style="margin-top: 20px"
              dense
              outlined
              type="error"
            >
              El evento tiene reservas
            </v-alert>
            <v-checkbox
              v-if="allowNotification"
              v-model="editedItem.sendOwnerMail"
              :label="$t('booking.notifications.sendNotificationOwnerMail', locale)"
              hide-details
            />
          </v-container>
        </v-card-text>
        <v-card-actions style="padding: 0 20px 20px 0">
          <v-spacer/>
          <v-btn
            color="default"
            text
            @click="() => { onClose() }"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            :elevation="0"
            color="primary"
            dark
            @click="handleMove"
          >
            {{ $t('common.move', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import MoveEventForm from './MoveEventForm'
export default {
  components: {
    MoveEventForm,
  },
  props: {
    eventID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    event: null,
    editedItem: null,
    showDialog: false,
    loading: false,
    minDate: utils.checkDate(new Date()),
    actionRequired: false,
    allowNotification: false,
  }),
  watch: {
    dialog () {
      this.getData()
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.getData()
    this.showDialog = this.dialog
  },
  methods: {
    getData () {
      if (!this.eventID) return
      api.getItem('booking', `v1/private/events/`, this.eventID)
        .then(response => {
          this.event = response
          this.editedItem = {
            time: this.event.Time,
            date: this.event.Date,
            sendOwnerMail: false,
          }
          this.actionRequired = response.Book > 0 || response.Cancel > 0
          if (this.actionRequired) this.handleGetWorkspaceConfig()
        })
    },
    handleGetWorkspaceConfig () {
      api.getItem('booking', `v1/private/workspaces/`, this.workspaceID)
        .then(response => {
          this.allowNotification = response.SendOwnerMail === 1
        })
    },
    handleChangeEvent (v) {
      this.editedItem.date = v.date
      this.editedItem.time = v.time
    },
    handleMove () {
      console.log(this.editedItem)
      api.updateItem('booking', `v1/private/events/`, this.eventID.concat('/move'), this.editedItem)
        .then(response => {
          console.log('response', response)
          if (response) {
            this.onClose(true)
          }
        })
    },
  },
}
</script>

